import myConfig from './myConfig';
import axios from 'axios';

const token = localStorage.getItem('token');
export default {
    getAll(callback){
        axios.get(myConfig.api_url+`/api/v1/allLogs?api_token=${token}`)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    add(args,callback){

        
        axios.post(myConfig.api_url+`/api/v1/addLog?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    update(args,callback){
        // id:?, designation:?,
        axios.post(myConfig.api_url+`/api/v1/updateLog?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    delete(args,callback){
        //id:? ,
        axios.post(myConfig.api_url+`/api/v1/deleteLog?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

}