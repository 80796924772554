<template>
  <aside class="main-sidebar">
    <section class="sidebar position-relative">
      <div class="multinav">
        <div class="multinav-scroll" style="height: 100%">
          <ul class="sidebar-menu" data-widget="tree">  
            <li v-if="role!=-1&&role!=4" class="treeview">
              <a class="treeview-link">
                <i class="fa fa-users"></i>
                <span>Clients</span>
                <span class="pull-right-container">
                  <i class="fa fa-angle-right pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu menuH">
                <li>
                  <router-link :to="{ name: 'Clients' }">
                    <span>Liste des clients</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Inscription' }">
                    <span>Liste des inscriptions</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Factures' }">
                    <span>Liste des factures clients</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'ResteAPayer' }">
                    <span>Liste des restes à payer </span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li v-if="role==-1" class="treeview">
              <router-link class="treeview-link" :to="{ name: 'PGroupes' }">
                <i class="fa fa-users"></i>
                <span>Groupes</span>
                <span></span>
              </router-link>
            </li>
            <li v-if="role!=-1&&role!=4" class="treeview">
              <a class="treeview-link">
                <i class="fa fa-users"></i>
                <span>Profs</span>
                <span class="pull-right-container">
                  <i class="fa fa-angle-right pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu menuH">
                <li>
                  <router-link :to="{ name: 'Seances' }">
                    <span>Liste des séances</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'ProfsAvance' }">
                    <span>Avances</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li v-if="role!=-1&&role!=4" class="treeview">
              <a class="treeview-link">
                <i class="fa fa-users"></i>
                <span>Groupes</span>
                <span class="pull-right-container">
                  <i class="fa fa-angle-right pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu menuH">
                <li>
                  <router-link :to="{ name: 'Groupes' }">
                    <span>Liste des groupes</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li v-if="role!=-1" class="treeview">
              <a class="treeview-link">
                <i class="fa fa-users"></i>
                <span>Caisse</span> 
                <span class="pull-right-container">
                  <i class="fa fa-angle-right pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu menuH">
                <li>
                  <router-link :to="{ name: 'Caisse' }">
                    <span>Caisse</span>
                  </router-link>
                </li>
                <li v-if="role==1">
                  <router-link :to="{ name: 'CaisseG' }">
                    <span>Caisse Global</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li v-if="role!=-1" class="treeview">
              <a class="treeview-link">
                <i class="fa fa-users"></i>
                <span>Comptabilité</span> 
                <span class="pull-right-container">
                  <i class="fa fa-angle-right pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu menuH">
                <li>
                  <router-link :to="{ name: 'Cheque' }">
                    <span>Liste des chèques</span>
                  </router-link>
                </li>
                <li v-if="role!=4">
                  <router-link :to="{ name: 'Depenses' }">
                    <span>Dépenses</span>
                  </router-link>
                </li>
                <li v-if="role!=4">
                  <router-link :to="{ name: 'MouvAssoc' }">
                    <span>Mouvements C.C.Associés</span>
                  </router-link>
                </li>
                <li v-if="role!=4">
                  <router-link :to="{ name: 'Assoc' }">
                    <span>C.C.Associés</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li v-if="role!=-1&&role!=4" class="treeview">
              <a class="treeview-link">
                <i class="fa fa-users"></i>
                <span>Statistiques</span>
                <span class="pull-right-container">
                  <i class="fa fa-angle-right pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu menuH">
                <li>
                  <router-link :to="{ name: 'RecapGroupe' }">
                    <span>Récap effectif des groupes</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'RecapInsc' }">
                    <span>Récap inscriptions</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li v-if="role==1||role==2" class="treeview">
              <a class="treeview-link">
                <i class="fa fa-cogs"></i>
                <span>Paramètres</span>
                <span class="pull-right-container">
                  <i class="fa fa-angle-right pull-right"></i>
                </span>
              </a>
              <ul class="treeview-menu menuH">
                <li>
                  <router-link :to="{ name: 'User' }">
                    <span>Utilisateurs</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Associes' }">
                    <span>Associes</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Annees' }">
                    <span>Annees scolaire</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Categorie' }">
                    <span>Categories d'age</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Niveau' }">
                    <span>Niveaux scolaires</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'NiveauB' }">
                    <span>Niveaux</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Matiere' }">
                    <span>Matiere</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Salle' }">
                    <span>Salles</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Horaire' }">
                    <span>Horaires</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Cadence' }">
                    <span>Cadences hebdomadaires</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Bareme' }">
                    <span>Bareme</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Convention' }">
                    <span>Convention</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Formule' }">
                    <span>Formule</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Documentation' }">
                    <span>Documentation</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Prof' }">
                    <span>Profs</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Groupe' }">
                    <span>Groupes</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Banque' }">
                    <span>Banques</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Rubrique' }">
                    <span>Rubriques</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'SousRubrique' }">
                    <span>Sous-Rubriques depenses</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Centre' }">
                    <span>Centre</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'ProfsAvanceH' }">
                    <span>Profs Avances logs</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'UsersAvanceH' }">
                    <span>Users Avances logs</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Logs' }">
                    <span>Logs</span>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!--<div class="sidebar-footer">
      <a href="javascript:void(0)" class="link" data-bs-toggle="tooltip" title="Settings"
        ><span class="icon-Settings-2"></span
      ></a>
      <a href="mailbox.html" class="link" data-bs-toggle="tooltip" title="Email"
        ><span class="icon-Mail"></span
      ></a>
      <a href="javascript:void(0)" class="link" data-bs-toggle="tooltip" title="Logout"
        ><span class="icon-Lock-overturning"
          ><span class="path1"></span><span class="path2"></span></span
      ></a>
    </div>-->
  </aside>
</template>

<script>
let $ = window.$;
let jQuery = window.$;
let bootstrap = window.bootstrap;
export default {

  mounted(){
    $(".treeview").on("click", ".treeview-link" , function (ee) {
      const e = ee.currentTarget;
      if(e.parentElement.children[1].className.indexOf("menuH")!=-1){
        const elements = document.getElementsByClassName("treeview-link");
        for(const el of elements){
          el.parentElement.children[1].className = el.parentElement.children[1].className.replace("menuS","menuH");
          el.children[2].children[0].className = el.children[2].children[0].className.replace("fa-angle-down","fa-angle-right");
        }
        e.parentElement.children[1].className = e.parentElement.children[1].className.replace("menuH","menuS");
        e.children[2].children[0].className = e.children[2].children[0].className.replace("fa-angle-right","fa-angle-down");
      }
      else{
        e.parentElement.children[1].className = e.parentElement.children[1].className.replace("menuS","menuH");
        e.children[2].children[0].className = e.children[2].children[0].className.replace("fa-angle-down","fa-angle-right");
      }
    });
  },
  methods: {
  },
  data(){
    return{
      role:localStorage.getItem("role"),
    };
  }
  
};
</script>

<style>
.menuH{
  display: none;
}
.menuS{
  display: block !important;
}
.treeview-link{
  user-select: none;
  cursor: pointer;
}
</style>
